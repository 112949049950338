<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <pre v-highlightjs><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'LifetimeDownsampled',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      matlabCode: '',
      writeRun: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.writeMatlab()
      },
      deep: true
    }
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)

    this.writeMatlab()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      // current settings

      const mode = ''
      let pnamesADC = ''
      let pnamesADClabel = ''
      let pnamesV = ''
      let pnamesVlabel = ''

      if (this.data.current_type === 'constant') {
        if (this.data.current_shape === 'pulse') {
          pnamesADC = this.led.internal_serialnumber + '-' + this.data.pulses_min + 'pps-' + this.data.temperature_min + 'C-ADC_Output'
          pnamesADClabel = this.led.internal_serialnumber + ' (' + this.data.pulses_min + 'pps, ' + this.data.temperature_min + 'C) downsampled'
          pnamesV = this.led.internal_serialnumber + '-' + this.data.pulses_min + 'pps-' + this.data.temperature_min + 'C-Voltage'
          pnamesVlabel = this.led.internal_serialnumber + ' (' + this.data.pulses_min + 'pps, ' + this.data.temperature_min + 'C) downsampled'
        } else {
          pnamesADC = this.led.internal_serialnumber + '-DC-' + this.data.current_min + 'mA-' + this.data.temperature_min + 'C-ADC_Output'
          pnamesADClabel = this.led.internal_serialnumber + ' (' + this.data.current_min + 'mA DC, ' + this.data.temperature_min + 'C) downsampled'
          pnamesV = this.led.internal_serialnumber + '-DC-' + this.data.current_min + 'mA-' + this.data.temperature_min + 'C-Voltage'
          pnamesVlabel = this.led.internal_serialnumber + ' (' + this.data.current_min + 'mA DC, ' + this.data.temperature_min + 'C) downsampled'
        }
      }
      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% plists for retrieving data\n' +
        'plRetrieve = plist(\'built-in\', \'retrieve_in_timespan\', ...\n' +
        '\'names\', \'\', ...\n' +
        '\'timespan\', timespan(time(\'2019-05-27 00:00:00\'), time()), ...\n' +
        '\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', \'ltpda_lifetime_test_2019_clean\');\n' +
        '\n' +
        '% retrieve, downsample, normalize to first hour (15 minutes into experiment)\n' +
        'pl_down = plist(\'factor\', 50);\n' +
        'plRetrieve.pset(\'names\', \'' + pnamesADC + '%\');\n' +
        'ao_adc = ao(plRetrieve);\n' +
        'ao_adc_down = downsample(ao_adc, pl_down);\n' +
        'ao_adc_mean = ao_adc/mean(ao_adc.data.y(90:8730));\n' +
        'ao_adc_down_mean = ao_adc_down/mean(ao_adc.data.y(90:8730));\n' +
        'iplot(ao_adc_mean, ao_adc_down_mean);\n' +
        'plRetrieve.pset(\'names\', \'' + pnamesV + '%\');\n' +
        'ao_v = ao(plRetrieve);\n' +
        'ao_v_down = downsample(ao_v, pl_down);\n' +
        'iplot(ao_v, ao_v_down);\n' +
        '\n' +
        '% create data file\n' +
        'ts1 = timespan(time(plist(\'MILLISECONDS\', [0])), ao_adc_down.t0);\n' +
        'fileID = fopen(\'xy.txt\', \'w\');\n' +
        '%write key labels (ADC)\n' +
        '    fprintf(fileID, \'"%s" \', \'' + 'Time' + '\');\n' +
        '    fprintf(fileID, \'"%s" \', [\'' + pnamesADClabel + ' /\', num2str(mean(ao_adc.data.y(90:8730))), \'V\']);\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(ao_adc_down.data.x)\n' +
        '    fprintf(fileID, \'%d \', getNsecs(ts1) + ao_adc_down.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', ao_adc_down_mean.data.y(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        'ts1 = timespan(time(plist(\'MILLISECONDS\', [0])), ao_v_down.t0);\n' +
        'fileID = fopen(\'xy2.txt\', \'w\');\n' +
        '%write key labels (V)\n' +
        '    fprintf(fileID, \'"%s" \', \'' + 'Time' + '\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + pnamesVlabel + '\');\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(ao_adc_down.data.x)\n' +
        '    fprintf(fileID, \'%d \', getNsecs(ts1) + ao_v_down.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', ao_v_down.data.y(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        'filetext = fileread(\'xy.txt\');\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'' + mode + '\', filetext, \'' + this.userData.api_key + '\')\n' +
        'filetext = fileread(\'xy2.txt\');\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'' + mode + '\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
      '% upload downsampled lifetime data to LTPDA and add pointers to CMSdb\n' +
        'ao_v_down.setName(ao_v.name);\n' +
        'ao_adc_down_mean.setName([ao_adc.name, \' /\', num2str(mean(ao_adc.data.y(90:8730))), \'V\']);\n' +
        'ao_adc_down_mean.setYaxisName(\'Normalized amplitude\');\n' +
        'adc_det = submit([ao_adc_down_mean], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'' + 'ltpda_lifetime_ds' + '\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': Downsampled cleaned-up lifetime time-series ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', [\'ADC read-out, downsampled (/50), normalized (/\'  num2str(mean(ao_adc.data.y(90:8730)))  \'V)\'], ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
      'submit_aolist(adc_det, \'' + this.data.files[0].name + '\', [ao_adc_down_mean], \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
        'v_det = submit([ao_v_down], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'' + 'ltpda_lifetime_ds' + '\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': Downsampled cleaned-up lifetime time-series ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'LED voltage, downsampled (/50)\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        'submit_aolist(v_det, \'' + this.data.files[0].name + '\', [ao_v_down], \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
      '\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
